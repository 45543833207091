<template>
  <div class="upload">
    <div class="box">
      <section class="box-add-btn" v-if="!imageUrl && !cropVisible">
        <img class="icon-add" src="../../assets/images/add@2x.png" alt="" />
        <p v-if="wordShow">点击或将图片拖拽到这里上传</p>
      </section>
      <img v-if="imageUrl && !cropVisible" class="upload-imagedata" :src="imageUrl" />
      <input
        class="upload-file"
        type="file"
        ref="uploader"
        @change="getFile"
        :accept="fileType"
      />
      <crop
        class="crop-image"
        :show.sync="cropVisible"
        :width="info.width"
        :height="info.height"
        :image="cropImage"
        @cancel="cancel"
        @confirm="confirm"
      />
    </div>
  </div>
</template>
<script>
import Crop from "./Crop.vue";
import { defineComponent, ref, watch } from "@vue/composition-api";

export default defineComponent({
  components: { Crop },
  model: {
    prop: "fileInfo",
    event: "change",
  },
  props: {
    fileType: { type: String, default: "image/*" },
    fileInfo: {},
    info: {},
    wordShow: { default: true }
  },
  setup(props, { refs, emit, root }) {
    const imageUrl = ref(props.info.url && !props.info.url.includes('http') ? '//'+props.info.url : (props.info.url || ''));
    const cropVisible = ref(false);
    const cropImage = ref("");
    watch(props.info,val=>{
      imageUrl.value = val.url && !val.url.includes('http') ? '//'+val.url : (val.url || '')
    })
    const getFile = () => {
      cropImage.value = ""
      const file = refs.uploader.files;
      const type = props.fileType.split('/')[0]
      if(!file[0].type.includes(type)) {
        root.$message.warning('该文件格式不支持上传')
        return
      }
      const fr = new FileReader();
      fr.readAsDataURL(file[0]);
      fr.onload = () => {
        cropVisible.value = true;
        cropImage.value = fr.result;
        refs.uploader.value = "";
      };
    };
    const cancel = () => {
      // imageUrl.value = props.info.url;
    };
    const baseToFile = (base64) => {
      const arr = base64.split(',')
      const type = arr[0].match(/:(.*?);/)[1];
      const bytes = atob(arr[1])
      let length = bytes.length
      const ua = new Uint8Array(length)
      while(length--) {
        ua[length] = bytes.charCodeAt(length)
      }
      const fileName = String.fromCharCode(Math.floor(Math.random() * 58) + 65)+Math.floor(Math.random()*100000)+'.'+type.split('/')[1]
      return new File([ua],fileName , { type: type })
    }
    const confirm = async (val) => {
      try {
        imageUrl.value = val
        const img = await baseToFile(val);
        uploadImg(img)
      } catch (error) {
        window.console.error(error)
      }
    }
    const uploadImg = async img => {
      let url = '/common/upload'
      try {
        const formdata = new FormData()
        formdata.append('img', img)
        if(props.info.requestUrl){
          url = props.info.requestUrl
          formdata.append('path', props.info.pathType)
        }
        const res = await root.$axios.post(url, formdata, {
          Headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        if(res.code === 10000) {
          root.$message.success('上传文件成功')
          emit("getimage", res.data);
          emit("change", img); 
        } else {
          root.$message.error('上传文件失败')
        }     
      } catch (error) {
        root.$message.error('上传文件失败')
      }
    }
    return {
      imageUrl,
      cropVisible,
      cropImage,
      getFile,
      cancel,
      confirm
    };
  },
});
</script>
<style lang="scss" scoped>
.upload {
  width: 100%;
  height: 100%;
  .box {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: relative;
    border: 2px dashed $lightColor;
    display: flex;
    align-items: center;
    justify-content: center;
    .box-add-btn{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
  .upload-imagedata {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .crop-image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  .upload-file {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100% !important;
    background-color: #fff;
    opacity: 0;
  }
  .icon-add {
    width: 36px;
    height: 36px;
    display: block;
    margin: 0 auto;
  }
  p {
    color: $textColor;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
  }
}
</style>