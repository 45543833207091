<template>
  <div class="go-crop" v-if="show">
    <canvas
      id="go-cropbox"
      @mousedown="start"
      @mousemove="move"
      @mouseup="end"
      @mouseover="end"
    ></canvas>
    <div style="display: flex; justify-content: space-around">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="exportImg">保存</el-button>
    </div>
  </div>
</template>
<script>
import { defineComponent, watch, ref } from "@vue/composition-api";

export default defineComponent({
  props: {
    image: { type: String, default: "" },
    width: { type: Number, default: 1340 },
    height: { type: Number, default: 740 },
    show: { type: Boolean, default: false },
  },
  setup(props, { root, emit }) {
    watch(
      () => props.width,
      (val) => {
        props.width = +val;
      }
    );
    watch(
      () => props.height,
      (val) => {
        props.height = +val;
      }
    );
    const clickFlag = ref(false);
    const ratio = 1;
    const mousePos = { x: null, y: null };
    const movePos = { x: 0, y: 0 };
    const distanceX = ref(0);
    const distanceY = ref(0);
    let canvas = "",ctx = ""
    const img = new Image();
    const imgSize = {
      w: 0,
      h: 0,
    };
    watch(
      () => props.image,
      (val) => {
        if (!val) return;
        root.$nextTick(() => {
          canvas = document.getElementById("go-cropbox");
          img.src = props.image;
        });
      }
    );
    img.onload = () => {
      imgSize.w = img.width > props.width ? props.width : img.width;
      imgSize.h = img.height > props.height ? props.height : img.height;
      if(img.width < props.width || img.height < props.height) {
        root.$message.warning('图片尺寸小于要求尺寸')
        emit("update:show", false);
        return
      }
      canvas.width = imgSize.w * ratio;
      canvas.height = imgSize.h * ratio;
      ctx = canvas.getContext("2d");
      cropRegion(0, 0);
    };
    const cropRegion = (x, y) => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img,x,y,canvas.width,canvas.height,0,0,img.width,img.height);
    };
    const exportImg = () => {
      const cv = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const _canvas = document.createElement("canvas");
      _canvas.width = imgSize.w * ratio;
      _canvas.height = imgSize.h * ratio;
      const _ctx = _canvas.getContext("2d");
      _ctx.putImageData(cv, 0, 0);
      emit("confirm", _canvas.toDataURL("image/png", 0.5));
      emit("update:show", false);
    };
    const start = (e) => {
      clickFlag.value = true;
      const { offsetX, offsetY } = e;
      mousePos.x = mousePos.x != null ? mousePos.x : offsetX * ratio;
      mousePos.y = mousePos.y != null ? mousePos.y : offsetY * ratio;
      movePos.x = offsetX;
      movePos.y = offsetY;
    };
    const move = (e) => {
      if (!clickFlag.value) return;
      const { offsetX, offsetY } = e;
      distanceX.value = offsetX - movePos.x;
      distanceY.value = offsetY - movePos.y;
      mousePos.x -= distanceX.value / 5;
      mousePos.y -= distanceY.value / 5;
      if (mousePos.x <= 0) {
        mousePos.x = 0;
      } else if (mousePos.x >= img.width - imgSize.w) {
        mousePos.x = img.width - imgSize.w;
      }
      if (mousePos.y <= 0) {
        mousePos.y = 0;
      } else if (mousePos.y >= img.height - imgSize.h) {
        mousePos.y = img.height - imgSize.h;
      }
      cropRegion(mousePos.x, mousePos.y);
    };
    const end = () => {
      clickFlag.value = false;
    };
    const cancel = () => {
      Object.assign(mousePos, { x: null, y: null });
      Object.assign(movePos, { x: null, y: null });
      Object.assign(imgSize, {
        w: 0,
        h: 0,
      });
      distanceX.value = "";
      distanceY.value = "";
      emit("cancel");
      emit("update:show", false);
    };
    return {
      clickFlag,
      start,
      move,
      end,
      exportImg,
      cancel,
    };
  },
});
</script>
<style lang="scss" scoped>
.go-crop {
  position: relative;
  background-color: #fff;
}
#go-cropbox {
  width: 100%;
  height: 100%;
  display: block;
}
</style>